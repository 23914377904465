import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const QuizText = loadable(() => import('./QuizText'));

Builder.registerComponent(withChildren(QuizText), {
  name: 'Slides:QuizText',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: ''
    }
  ]
});
