import React from 'react';
import loadable from '@loadable/component';
import TubSelectorV1 from './TubSelectorV1';
import TubSelectorV1WithChildren from './TubSelectorV1WithChildren';
import TubSelectorV2 from './TubSelectorV2';
import TubSelectorV3 from './TubSelectorV3';

const TubSelectorDropDown = loadable(() => import('./TubSelectorDropDown'));

const TubSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version3':
      return <TubSelectorV3 {...props} />;
    case 'version2':
      return <TubSelectorV2 {...props} />;
    case 'dropdown':
      return <TubSelectorDropDown {...props} />;
    case 'version1-with-children':
      return <TubSelectorV1WithChildren {...props} />;
    default:
    case 'version1':
      return <TubSelectorV1 {...props} />;
  }
};

export default TubSelector;
