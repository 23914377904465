import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const ProductItemText = loadable(() => import('./ProductItemText'));

Builder.registerComponent(ProductItemText, {
  name: 'Product Item Text',
  noWrap: true,
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: '[currency-symbol] [savings] [discounted-price] [price]',
      helperText: `Template Tags: [currency-symbol] [discounted-price] [price] 
      [discounted-price-per-day] [price-per-day]
      [discounted-price-per-chew] [price-per-chew]
      [quantity] [image] [perceived_rrp] [discount_percentage] [display_title] 
      [bump-discounted-price] [bump-price] [bump-image]
      [savings] [savings-per-tub] [savings-per-chew] [savings-per-day] [savings-per-month]
      `
    },
    {
      name: 'currentCategory',
      type: 'string',
      defaultValue: 'subscriptions',
      enum: ['subscriptions', 'onetime']
    },
    {
      name: 'currentTubIndex',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'bumpOfferIndex',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'chewsPerTub',
      type: 'number',
      defaultValue: 30
    },
    {
      name: 'subSavingsAddon',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'otpSavingsAddon',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'pricesDividedBy',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'savingsDividedBy',
      type: 'number',
      defaultValue: 1
    }
  ]
});
