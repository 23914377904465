import { Builder, withChildren } from '@builder.io/react';
import BumpOfferSelector from './BumpOfferSelector';

Builder.registerComponent(withChildren(BumpOfferSelector), {
  name: 'Bump Offer Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'style',
      type: 'string',
      enum: ['default', 'rift', 'compact'],
      defaultValue: 'default'
    },
    {
      name: 'bumpOfferIndex',
      type: 'number',
      defaultValue: 0,
      required: false
    },
    {
      name: 'hideBumpOfferDiscount',
      type: 'boolean',
      defaultValue: false,
      required: false
    },
    {
      name: 'bumpOfferPrefixText',
      type: 'string',
      defaultValue: 'Enhance Your Chews With Our',
      required: false
    },
    {
      name: 'hideRibbon',
      type: 'boolean',
      defaultValue: false,
      required: false
    },
    {
      name: 'indexSettings',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'richText',
          defaultValue: 'Add another bottle for just [discounted-price]!',
          required: false
        },
        {
          name: 'prefixText',
          type: 'richText',
          defaultValue: 'Enhance Your Chews With Our',
          required: true
        },
        {
          name: 'useTemplateTags',
          type: 'boolean',
          defaultValue: false,
          required: false
        }
      ],
      showIf: o => o.get('designVersion') === 'version2'
    }
  ]
});
