import { Builder, withChildren } from '@builder.io/react';
import AddToLocalCartButton from './AddToLocalCartButton';

Builder.registerComponent(withChildren(AddToLocalCartButton), {
  name: 'Add To Local Cart Button',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  noWrap: true,
  inputs: [
    {
      name: 'type',
      type: 'string',
      defaultValue: 'product',
      enum: ['product', 'bump-offer']
    },
    {
      name: 'currentCategory',
      type: 'string',
      defaultValue: 'subscriptions',
      enum: ['subscriptions', 'onetime']
    },
    {
      name: 'currentTubIndex',
      type: 'number',
      defaultValue: 0
    }
  ]
});
