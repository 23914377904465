import { Builder } from '@builder.io/react';
import Header from './Header';

Builder.registerComponent(Header, {
  name: 'Header',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'logo',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png']
    },
    {
      name: 'sticky',
      type: 'boolean',
      defaultValue: false,
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'navLinks',
      type: 'list',
      defaultValue: false,
      showIf: options => options.get('designVersion') === 'version2',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Link' },
        { name: 'link', type: 'string', defaultValue: '#Link' },
        { name: 'openInNewWindow', type: 'boolean', defaultValue: false }
      ]
    }
  ]
});
