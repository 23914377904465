import styled from 'styled-components';

export const Container = styled.div`
  &.sticky {
    padding: 10px 20px 25px;
  }
`;

export const Banner = styled.div`
  text-align: center;
  background: #001c72;
  padding: 10px 0 10px;
  color: #fff;
  z-index: 10;
  width: 100%;
  top: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  &.sticky {
    position: fixed;
  }
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;
