import React from 'react';
import PackageSelectorV1 from './PackageSelectorV1';
import PackageSelectorFreeTrial from './PackageSelectorFreeTrial';
import PackageSelectorV2 from './PackageSelectorV2';

const PackageSelector = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <PackageSelectorV2 {...props} />;
    case 'free-trial':
      return <PackageSelectorFreeTrial {...props} />;
    default:
    case 'version1':
      return <PackageSelectorV1 {...props} />;
  }
};

export default PackageSelector;
