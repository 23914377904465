import { Builder, withChildren } from '@builder.io/react';
import PackageSelector from './PackageSelector';

Builder.registerComponent(withChildren(PackageSelector), {
  name: 'Package Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'free-trial', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'style',
      type: 'string',
      enum: ['default', 'rift'],
      defaultValue: 'default'
    },
    {
      name: 'subscriptionSchedules',
      type: 'list',
      defaultValue: [
        { id: '1:month', label: 'Every 4 weeks (Most Common)' },
        { id: '2:month', label: '2 Months' },
        { id: '3:month', label: '3 Months' }
      ],
      subFields: [
        {
          name: 'id',
          type: 'string',
          defaultValue: '1:month',
          required: true
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Month (Most Common)',
          required: true
        },
        {
          name: 'defaultSelection',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'extraObjects',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              defaultValue: '',
              required: true
            },
            {
              name: 'value',
              type: 'string',
              defaultValue: '',
              required: true
            }
          ]
        }
      ],
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'faqURL',
      type: 'string',
      advanced: true,
      defaultValue:
        'https://thepetlabco.com/pages/our-most-frequently-asked-questions',
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'showPriceComparison',
      type: 'boolean',
      defaultValue: true,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'invertPriceComparison',
      type: 'boolean',
      defaultValue: true,
      advanced: true,
      showIf: options =>
        options.get('showPriceComparison') &&
        ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideSubsTab',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideOtpTab',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideSubsSavings',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideOtpSavings',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideParenthesesSavings',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'useSelectComponent',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'showKlarna',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'subKlarna',
      type: 'boolean',
      defaultValue: false,
      showIf: options => options.get('showKlarna')
    },
    {
      name: 'subKlarnaInstallments',
      type: 'number',
      defaultValue: 3,
      showIf: options => options.get('subKlarna')
    },
    {
      name: 'otpKlarna',
      type: 'boolean',
      defaultValue: false,
      showIf: options => options.get('showKlarna')
    },
    {
      name: 'otpKlarnaInstallments',
      type: 'number',
      defaultValue: 3,
      showIf: options => options.get('otpKlarna')
    },
    {
      name: 'showBandanaSelector',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'percentageSavings',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'subscriptionInfo',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'bandanaSelectorItems',
      type: 'list',
      defaultValue: '',
      showIf: options => options.get('showBandanaSelector'),
      subFields: [
        {
          name: 'variantId',
          type: 'number',
          defaultValue: '1'
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Label'
        },
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'default',
          type: 'boolean',
          defaultValue: false
        }
      ]
    },

    // free gift
    {
      name: 'withFreeGift',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },

    {
      name: 'subLockInEnabled',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'subLockInPeriods',
      type: 'list',
      defaultValue: [
        { value: '3-month', label: '3 Months' },
        { value: '6-month', label: '6 Months' },
        { value: '12-month', label: '12 Months' }
      ],
      subFields: [
        {
          name: 'value',
          type: 'string',
          defaultValue: '3-month',
          required: true
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: '3 Months',
          required: true
        },
        {
          name: 'default',
          type: 'boolean',
          defaultValue: false,
          required: true
        }
      ],
      showIf: options => options.get('subLockInEnabled')
    },
    {
      name: 'currencySymbolToEnd',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'commaDecimalPoint',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'tabTwoLines',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideTabSavings',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'hideMainChild',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'useChildrenInTabContent',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'tabContentChildIndexForSub',
      type: 'number',
      defaultValue: 0,
      advanced: true,
      showIf: options => options.get('useChildrenInTabContent')
    },
    {
      name: 'tabContentChildIndexForOtp',
      type: 'number',
      defaultValue: 0,
      advanced: true,
      showIf: options => options.get('useChildrenInTabContent')
    },
    {
      name: 'editLanguage',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => ['version1'].includes(options.get('designVersion'))
    },
    {
      name: 'language',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'tabSubscribe',
          type: 'string',
          defaultValue: 'Subscribe'
        },
        {
          name: 'tabOnetime',
          type: 'string',
          defaultValue: 'One-time'
        },
        {
          name: 'tabSave',
          type: 'string',
          defaultValue: 'save'
        },
        {
          name: 'deliveryFrequency',
          type: 'string',
          defaultValue: 'Delivery Frequency'
        },
        {
          name: 'benefitOneTitle',
          type: 'string',
          defaultValue: 'Flexible subscription plan'
        },
        {
          name: 'benefitOneSubTitle',
          type: 'string',
          defaultValue: 'Change, pause or skip your delivery'
        },
        {
          name: 'benefitTwoTitle',
          type: 'string',
          defaultValue: 'Easy Cancel'
        },
        {
          name: 'benefitTwoSubTitle',
          type: 'string',
          defaultValue: 'You can |manage or cancel your subscription online',
          helperText: `Add | in between text to correct the value`
        },
        {
          name: 'benefitTwoPopupVersion',
          type: 'string',
          enum: ['english', 'french', 'spanish', 'german'],
          defaultValue: `english`
        },
        {
          name: 'onetimeContent',
          type: 'richText',
          defaultValue: `You can have the same for only <strong>[sub-price] [free-gift]</strong> if you subscribe. That’s a saving of [savings] and you can change or cancel your subscription! 😱😱😱`,
          helperText: `Template Tags: [sub-price] [savings] [currency-symbol]`
        },
        {
          name: 'withFreeGift',
          type: 'string',
          defaultValue: ` + A FREE Gift`
        }
      ],
      showIf: options =>
        options.get('editLanguage') &&
        ['version1', 'version2'].includes(options.get('designVersion'))
    },
    {
      name: 'subSavingsAddon',
      type: 'number',
      defaultValue: 0.0,
      advanced: true
    },
    {
      name: 'otpSavingsAddon',
      type: 'number',
      defaultValue: 0.0,
      advanced: true
    }
  ]
});
