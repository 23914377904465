import { Builder, withChildren } from '@builder.io/react';
import LockInSelector from './LockInSelector';

Builder.registerComponent(withChildren(LockInSelector), {
  name: 'Lock-in Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'version3'],
      defaultValue: 'version1'
    },
    {
      name: 'periods',
      type: 'list',
      showIf: options =>
        ['version1', 'version3'].includes(options.get('designVersion')),
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '1 Month Plan -',
          required: true
        },
        {
          name: 'line1',
          type: 'string',
          defaultValue: ''
        },
        {
          name: 'line2',
          type: 'string',
          defaultValue: ''
        },
        {
          name: 'titleDiscount',
          type: 'string',
          defaultValue: 'XX% OFF',
          required: false
        },
        {
          name: 'subTitle',
          type: 'string',
          defaultValue: 'Auto renews every month',
          required: false
        },
        {
          name: 'pricePerDay',
          type: 'string',
          defaultValue: '$X.XX per day',
          required: false,
          helperText: `Template Tags: [price-per-chew] [price-per-day] [price] [discounted-price] [currency-symbol]`
        },
        {
          name: 'pricePerMonth',
          type: 'string',
          defaultValue: '$X.XX / month',
          required: false,
          helperText: `Template Tags: [price-per-chew] [price-per-day] [price] [discounted-price] [currency-symbol]`
        },
        {
          name: 'selected',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'value',
          type: 'string',
          defaultValue: '1-month',
          required: false
        },
        {
          name: 'chewsPerTub',
          type: 'number',
          defaultValue: 30,
          required: false
        },
        {
          name: 'periodLength',
          type: 'number',
          defaultValue: 1,
          required: false
        },
        {
          name: 'isMathFloorPerChew',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'isMathFloorPerMonth',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'category',
          type: 'string',
          enum: ['subscriptions', 'onetime'],
          defaultValue: 'subscriptions',
          required: false
        },
        {
          name: 'hasBorder',
          type: 'boolean',
          defaultValue: false,
          required: false
        }
      ]
    },
    {
      name: 'periods',
      type: 'list',
      showIf: options => options.get('designVersion') === 'version2',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '1 Month Plan -',
          required: true
        },
        {
          name: 'titleDiscount',
          type: 'string',
          defaultValue: 'XX% OFF',
          required: false
        },
        {
          name: 'subTitle',
          type: 'string',
          defaultValue: 'Auto renews every month',
          required: false
        },
        {
          name: 'mainPrice',
          type: 'string',
          defaultValue: '$X.XX / month'
        },
        {
          name: 'subPrice',
          type: 'string',
          defaultValue: '$X.XX / box'
        },
        {
          name: 'isWholePeriodMainPrice',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'isWholePeriodSubPrice',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'selected',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'value',
          type: 'string',
          defaultValue: '1-month',
          required: false
        },
        {
          name: 'chewsPerTub',
          type: 'number',
          defaultValue: 30,
          required: false
        },
        {
          name: 'periodLength',
          type: 'number',
          defaultValue: 1,
          required: false
        },
        {
          name: 'isMathFloorMainPrice',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'isMathFloorSubPrice',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'category',
          type: 'string',
          enum: ['subscriptions', 'onetime'],
          defaultValue: 'subscriptions',
          required: false
        }
      ]
    }
  ]
});
