import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const AnswerInput = loadable(() => import('./AnswerInput'));

Builder.registerComponent(AnswerInput, {
  name: 'Slides:AnswerInput',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'type',
      type: 'text',
      enum: [
        'text',
        'number',
        'email',
        'url',
        'checkbox',
        'radio',
        'range',
        'date',
        'datetime-local',
        'search',
        'tel',
        'time',
        'file',
        'month',
        'week',
        'password',
        'color',
        'hidden'
      ],
      defaultValue: 'text'
    },
    {
      name: 'name',
      type: 'string',
      required: true,
      helperText: 'object key'
    },
    {
      name: 'placeholder',
      type: 'string',
      defaultValue: 'Hello there',
      helperText: 'Text to display when there is no value'
    },
    // TODO: handle value vs default value automatically like ng-model
    {
      name: 'defaultValue',
      type: 'string'
    },
    {
      name: 'value',
      type: 'string',
      advanced: true
    },

    {
      name: 'required',
      type: 'boolean',
      helperText: 'Is this input required to be filled out to submit a form',
      defaultValue: false
    },

    {
      name: 'clearOldValue',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'addToExtraObjects',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'disabled',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    }
  ],
  noWrap: true,
  static: true,
  defaultStyles: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '3px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc'
  }
});
