import { Builder, withChildren } from '@builder.io/react';
import TubSelector from './TubSelector';

Builder.registerComponent(withChildren(TubSelector), {
  name: 'Tub Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: [
        'version1',
        'version1-with-children',
        'dropdown',
        'version2',
        'version3'
      ],
      defaultValue: 'version1'
    },
    {
      name: 'tubs',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '1 Tub',
          required: true
        },
        {
          name: 'subTitle',
          type: 'string',
          defaultValue: '',
          required: false
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'image2',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'showPrice',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'hideLabel',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'hideSubLabel',
          type: 'boolean',
          defaultValue: true,
          required: false
        },
        {
          name: 'quantity',
          type: 'number',
          defaultValue: 1,
          required: false
        },
        {
          name: 'chewsPerQuantity',
          type: 'number',
          defaultValue: 30,
          required: false
        },
        {
          name: 'backgroundColor',
          type: 'color',
          defaultValue: ''
        },
        {
          name: 'discountTagBg',
          type: 'color',
          defaultValue: ''
        },
        {
          name: 'discountTagBgSub',
          type: 'color',
          defaultValue: ''
        },
        {
          name: 'defaultValue',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'showImage',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'removeMargin',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'imageHeight',
          type: 'number',
          defaultValue: 58,
          required: false
        },
        {
          name: 'showTag',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'tagText',
          type: 'string',
          defaultValue: '',
          required: false
        },
        {
          name: 'extraObjects',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              required: true
            },
            {
              name: 'value',
              type: 'string',
              required: false
            }
          ],
          advanced: true
        },
        {
          name: 'bumpOfferIndex',
          type: 'number',
          defaultValue: 0,
          required: false,
          advanced: true
        }
      ]
      // showIf: (options) => (options.get('designVersion') === 'version1')
    },
    {
      name: 'boxDesign',
      type: 'string',
      enum: ['normal', 'wide', 'one-row'],
      defaultValue: 'normal',
      advanced: true
    },
    {
      name: 'boxDesignMobile',
      type: 'string',
      enum: ['normal', 'wide', 'one-row'],
      defaultValue: 'one-row',
      advanced: true
    },
    {
      name: 'showChildren',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'showInnerChild',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('designVersion') === 'version1'
    },
    {
      name: 'showInnerChild',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('designVersion') === 'version1'
    },
    {
      name: 'collapsible',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'isExpanded',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'checkLocalStorage',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'checkLocalStorage',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    }
  ]
});
