import { Builder } from '@builder.io/react';
import TopBanner from './TopBanner';

Builder.registerComponent(TopBanner, {
  name: 'Top Banner',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'version3', 'version4'],
      defaultValue: 'version1'
    },
    {
      name: 'content',
      type: 'string',
      defaultValue: 'Petlab Co. Rated 5 Stars By Over 3000 Owners',
      showIf: options =>
        options.get('designVersion') === 'version1' || 'version4'
    },
    {
      name: 'banner_text',
      type: 'string',
      defaultValue: 'Limited stock only',
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'banner_text_two',
      type: 'string',
      defaultValue: 'Pre-order yours now before stock runs out!',
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'banner_icon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'banner_icon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      showIf: options => options.get('designVersion') === 'version3'
    },
    {
      name: 'sticky',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
