import React, { useState } from 'react';
import * as S from './NavLinksV1.styles';

const NavLinks = props => {
  const {
    navLinks,
    textColor,
    textColorMobile,
    padding,
    paddingMob,
    marginTopMob,
    iconPosition = 'right',
    iconTransform = 'noTransform',
    burgerIconColor = 'icon2'
  } = props;
  const [showNav, setShowNav] = useState(false);

  const NavLinks = () => {
    const dLinks = navLinks || [
      {
        name: 'Benefits',
        link: '#NavBenefits',
        openInNewWindow: false
      },
      {
        name: 'Testimonials',
        link: '#NavTestimonials',
        openInNewWindow: false
      },
      {
        name: 'Ingredients',
        link: '#NavIngredients',
        openInNewWindow: false
      },
      {
        name: 'FAQ',
        link: '#NavFAQ',
        openInNewWindow: false
      }
    ];

    return (
      <S.NavLinks className={showNav ? 'active' : ''}>
        {dLinks.map(({ name, link, openInNewWindow }) => {
          return (
            <S.Link
              textColor={textColor}
              textColorMobile={textColorMobile}
              padding={padding}
              paddingMob={paddingMob}
              onClick={() => setShowNav(false)}
              key={name}
              href={link}
              target={openInNewWindow ? '_blank' : ''}
            >
              {name}
            </S.Link>
          );
        })}
      </S.NavLinks>
    );
  };

  const triggerNav = e => {
    e.persist();
    setShowNav(!showNav);
    setTimeout(() => {
      window.scrollTo(window.scrollX, window.scrollY + 1);
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scroll(window.scrollX, window.scrollY + 1);
      window.scroll(window.scrollX, window.scrollY - 1);
    }, 1);
  };

  return (
    <S.Container {...props.attributes}>
      <S.Nav>
        <NavLinks />
      </S.Nav>
      <S.MobileNav
        marginTopMob={marginTopMob}
        textColor={textColor}
        iconPosition={iconPosition}
        iconTransform={iconTransform}
        onClick={triggerNav}
      >
        <i className={`icon icon-bars ${burgerIconColor}`}></i>
      </S.MobileNav>
    </S.Container>
  );
};

export default NavLinks;
