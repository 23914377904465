import styled from 'styled-components';

export const Container = styled.div`
  color: inherit;
  @media (max-width: 640px) {
    position: absolute !important;
    width: calc(100% - 30px) !important;
    z-index: 999;
  }
`;

export const Nav = styled.div`
  @media (max-width: 640px) {
    width: 100% !important;
    margin-top: 50px;
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const NavLinks = styled.div`
  @media (max-width: 640px) {
    width: 100%;
    display: none;
    &.active {
      display: block;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;

  ${({ textColor }) => {
    return `color: ${textColor ?? '#001C72'}!important;`;
  }}
  ${({ padding }) => {
    return `padding: ${padding ?? '10px'}!important;`;
  }}
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 640px) {
    width: 100%;
    display: block;
    ${({ textColorMobile }) => {
      return `color: ${textColorMobile ?? '#001C72'}!important;`;
    }}
    ${({ paddingMob }) => {
      return `padding: ${paddingMob ?? '10px'}!important;`;
    }}
  }
`;

export const MobileNav = styled.a`
  display: none;
  font-size: 25px;
  cursor: pointer;
  margin-left: auto;
  margin-top: 10px;

  ${({ textColor }) => {
    return `color: ${textColor ?? '#001C72'}!important;`;
  }};

  @media (max-width: 640px) {
    display: block;
    position: absolute;
    ${({ marginTopMob }) => {
      return `margin-top: ${marginTopMob ?? '10px'};`;
    }}
    ${({ iconTransform }) => {
      return `transform: ${
        iconTransform === 'noTransform' ? 'none;' : 'rotateX(45deg);'
      }`;
    }}
    top: 0;
    ${({ iconPosition }) => {
      return `${iconPosition}: 0`;
    }}
  }
`;
