import React from 'react';
import loadable from '@loadable/component';

const LockInSelectorV1 = loadable(() => import('./LockInSelectorV1'));
const LockInSelectorV2 = loadable(() => import('./LockInSelectorV2'));
const LockInSelectorV3 = loadable(() => import('./LockInSelectorV3'));

const LockInSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version3':
      return <LockInSelectorV3 {...props} />;
    case 'version2':
      return <LockInSelectorV2 {...props} />;
    default:
    case 'version1':
      return <LockInSelectorV1 {...props} />;
  }
};

export default LockInSelector;
