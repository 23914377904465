import { Builder } from '@builder.io/react';
import ImageCarousel from './ImageCarousel';

Builder.registerComponent(ImageCarousel, {
  name: 'Image Carousel',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'images',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'alt',
          type: 'string',
          defaultValue: 'Image Item'
        },
        {
          name: 'subscription_0',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'subscription_1',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'subscription_2',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'subscription_3',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'onetime_0',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'onetime_1',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'onetime_2',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'onetime_3',
          type: 'boolean',
          defaultValue: true
        }
      ]
    },
    {
      name: 'settings',
      type: 'object',
      advanced: true,
      defaultValue: {
        showStatus: false,
        thumbWidth: 80,
        swipeScrollTolerance: 5,
        autoPlay: false,
        centerMode: false,
        emulateTouch: false,
        infiniteLoop: false,
        interval: 3000,
        preventMovementUntilSwipeScrollTolerance: false,
        stopOnHover: false,
        swipeable: true,
        useKeyboardArrows: false,
        selectedItem: 0,
        showArrows: true,
        showIndicators: true,
        showThumbs: true,
        thumbsPosition: 'bottom',
        ariaLabel: '',
        axis: 'horizontal',
        dynamicHeight: false,
        transitionTime: 500
      },
      subFields: [
        {
          name: 'ariaLabel',
          type: 'string',
          defaultValue: ''
        },
        {
          name: 'axis',
          type: 'string',
          defaultValue: 'horizontal',
          enum: ['horizontal', 'vertical']
        },
        // {
        //   name: 'autoFocus',
        //   type: 'boolean',
        //   defaultValue: false,
        // },
        {
          name: 'autoPlay',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'centerMode',
          type: 'boolean',
          defaultValue: false
        },
        // {
        //   name: 'centerSlidePercentage',
        //   type: 'number',
        //   defaultValue: 0,
        // },
        {
          name: 'dynamicHeight',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'emulateTouch',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'infiniteLoop',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'interval',
          type: 'number',
          defaultValue: 3000
        },
        {
          name: 'preventMovementUntilSwipeScrollTolerance',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'selectedItem',
          type: 'number',
          defaultValue: 0
        },
        {
          name: 'showArrows',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'showStatus',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'showIndicators',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'showThumbs',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'thumbsPosition',
          type: 'string',
          enum: ['top', 'bottom', 'left', 'right'],
          defaultValue: 'bottom'
        },
        {
          name: 'thumbsHideOnMobile',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'indicatorsHideOnDesktop',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'stopOnHover',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'swipeable',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'swipeScrollTolerance',
          type: 'number',
          defaultValue: 5
        },
        {
          name: 'thumbWidth',
          type: 'number',
          defaultValue: 80
        },
        {
          name: 'transitionTime',
          type: 'number',
          defaultValue: 500
        },
        {
          name: 'useKeyboardArrows',
          type: 'boolean',
          defaultValue: false
        },
        // {
        //   name: 'verticalSwipe',
        //   type: 'string',
        //   defaultValue: 'standard',
        //   enum: ['standard','natural']
        // },
        // {
        //   name: 'width',
        //   type: 'number',
        //   defaultValue: 100,
        // },
        {
          name: 'arrowSettings',
          type: 'object',
          subFields: [
            {
              name: 'arrowColor',
              type: 'color'
            },

            {
              name: 'arrowOpacity',
              type: 'string',
              enum: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              defaultValue: 100
            },
            {
              name: 'arrowHoverOpacity',
              type: 'string',
              enum: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              defaultValue: 100
            },
            {
              name: 'arrowBgColor',
              type: 'color'
            },
            {
              name: 'arrowHoverBgColor',
              type: 'color'
            },
            {
              name: 'arrowStyle',
              type: 'string',
              enum: ['default', '<LTS - GTS>', '<SM - CEN>'],
              defaultValue: 'default'
            }
          ]
        }
      ]
    }
  ]
});
