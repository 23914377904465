import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = props => {
  const { children } = props;
  const el = document.createElement('div');
  const parent = document.getElementById('___gatsby');
  useEffect(() => {
    parent.appendChild(el);
    return () => parent.removeChild(el);
  }, [el, parent]);
  return ReactDOM.createPortal(children, el);
};

export default Modal;
