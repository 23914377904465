import { Builder } from '@builder.io/react';
import OkendoReviewsWidget from './OkendoReviewsWidget';

Builder.registerComponent(OkendoReviewsWidget, {
  name: 'Okendo Reviews Widget',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'subscriberId',
      type: 'string',
      defaultValue: 'a6ff1c99-dd8a-4420-be09-f24d9bd7dee3'
    },
    {
      name: 'productId',
      type: 'string',
      defaultValue: '6645452079159'
    }
  ]
});
