import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  letter-spacing: 0;
  text-transform: uppercase;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  border: 0;
  line-height: 0;
  margin: 20px auto 20px;
  font-size: 30px;
  font-weight: 900;
  position: relative;
  &:after {
    content: '';
    border-bottom: 2px solid #707070;
    width: 90%;
    height: 2px;
    position: absolute;
    left: 5%;
    z-index: 1;
    bottom: 0;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
            max-width: 1150px;
            &:after {
              width: 100%;
              left: 0;
              border-bottom: 2px solid #081E6D;
            }
            `;
      default:
        return ``;
    }
  }};

  @media (max-width: 375px) {
    line-height: inherit;
    &:after {
      top: 20px;
    }
  }
`;

export const Title = styled.span`
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 2;

  @media ${queries.md} {
    font-size: 20px;
    padding: 0 5px;
  }
`;
