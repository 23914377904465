import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const Footer = loadable(() => import('./Footer'));

Builder.registerComponent(Footer, {
  name: 'Footer',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'content',
      type: 'richText',
      defaultValue: '',
      showIf: options => options.get('designVersion') === 'version1'
    },
    {
      name: 'links',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'linkName',
          type: 'string',
          defaultValue: '',
          required: true
        },
        {
          name: 'linkUrl',
          type: 'url',
          defaultValue: '',
          required: true
        }
      ],
      showIf: options => options.get('designVersion') === 'version1'
    },
    {
      name: 'newsletterTitle',
      type: 'string',
      required: true
    },
    {
      name: 'newsletterBlurb',
      type: 'richText'
    },
    {
      name: 'linkGroup',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'groupTitle', type: 'string' },
        {
          name: 'links',
          type: 'list',
          defaultValue: [],
          subFields: [
            {
              name: 'linkName',
              type: 'string',
              defaultValue: '',
              required: true
            },
            {
              name: 'linkUrl',
              type: 'url',
              defaultValue: '',
              required: true
            }
          ]
        }
      ],
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'socialNetworks',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true
        },
        {
          name: 'link',
          type: 'string',
          defaultValue: '',
          required: true
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
});
