import React, { useState } from 'react';
import * as S from './Toggle.styles';
import Modal from '../Modal/Modal';

function Toggle({ isModal = false, children }) {
  const [open, setOpen] = useState(false);

  const Content = ({ isModal = false, children }) => {
    return (
      <>
        <S.Content modal={isModal}>
          {isModal && children && children[2] && (
            <S.Close onClick={() => setOpen(!open)}>
              {children && children[2]}
            </S.Close>
          )}
          {children && children[1]}
        </S.Content>
        {isModal && <S.Wrapper onClick={() => setOpen(!open)}></S.Wrapper>}
      </>
    );
  };

  return (
    <>
      <S.Trigger onClick={() => setOpen(!open)}>
        {(children && children[0]) || `no Trigger component, add first child`}
      </S.Trigger>
      {open && (
        <>
          {children && children[1] && (
            <>
              {!isModal && <Content isModal={false}>{children}</Content>}
              {isModal && (
                <Modal>
                  <Content isModal={true}>{children}</Content>
                </Modal>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Toggle;
