import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const Ingredient = loadable(() => import('./Ingredient'));

Builder.registerComponent(Ingredient, {
  name: 'Ingredient',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'name',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'description',
      type: 'richText',
      defaultValue: ''
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png']
    },
    {
      name: 'centered',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
