import { Builder } from '@builder.io/react';

import './LayerContainer/LayerContainer.builder';
import './NextButtonContainer/NextButtonContainer.builder';
import './BackButtonContainer/BackButtonContainer.builder';
import './QuizText/QuizText.builder';
import './AnswerInput/AnswerInput.builder';
import './AnswerSelect/AnswerSelect.builder';
import './Loading/Loading.builder';
import './CanvasScratch/CanvasScratch.builder';
import './AnswerContainer/AnswerContainer.builder';
import './ToggleContainer/ToggleContainer.builder';
import './NextLayerTimer/NextLayerTimer.builder';

// groupings
Builder.register('insertMenu', {
  name: 'Slide / Quiz Components',
  items: [
    { name: 'Slides:AnswerSelect' },
    { name: 'Slides:AnswerInput' },
    { name: 'Slides:BackButtonContainer' },
    { name: 'Slides:CanvasScratch' },
    { name: 'Slides:LayerContainer' },
    { name: 'Slides:Loading' },
    { name: 'Slides:NextButtonContainer' },
    { name: 'Slides:NextLayerTimer' },
    { name: 'Slides:QuizText' },
    { name: 'Slides:AnswerContainer' },
    { name: 'Slides:ToggleContainer' }
  ]
});
