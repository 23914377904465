import styled from 'styled-components';

export const Container = styled.div`
  &.sticky {
    padding: 10px 0 25px;
  }
`;

export const Banner = styled.div`
  text-align: center;
  background: #23252a;
  padding: 10px 0 5px;
  color: #fff;
  z-index: 10;
  width: 100%;
  height: auto;
  top: 0;
  &.sticky {
    position: fixed;
  }
`;

export const BannerTextOne = styled.span`
  font-weight: 600;
  vertical-align: middle;
`;

export const BannerTextTwo = styled.span`
  display: block;
  font-weight: 600;
`;
export const BannerIcon = styled.img`
  max-width: 17px;
  margin: -3px 10px;
`;
