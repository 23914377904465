import React, { useContext, useState } from 'react';
import * as S from './PackageSelectorV2.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import Select from 'react-select';
import loadable from '@loadable/component';

const SubPopup = loadable(() => import('./SubPopup'));

function PackageSelectorV2(props) {
  const { faqURL, language, useSelectComponent, subscriptionSchedules } = props;

  const {
    currency,
    currentCategory,
    setCurrentCategory,
    currentTubIndex,
    setCurrentProduct,
    frequency,
    setFrequency
  } = useContext(FunnelContext);

  const [showSubPopup, setShowSubPopup] = useState(false);

  const handleCategoryClick = category => {
    if (category !== currentCategory) {
      setCurrentCategory(category);
      setCurrentProduct(
        category === 'subscriptions'
          ? `subscription_${currentTubIndex}`
          : `onetime_${currentTubIndex}`
      );
    }
  };

  const currencySymbol = currency?.symbol || `$`;

  // language
  const langTabOnetime = language?.tabOnetime ?? `One-Time Purchase`;
  const langTabSubscribe = language?.tabSubscribe ?? `Subscribe & Save`;

  // prices
  const discountedPriceOTP = ProductPrice({
    type: `discounted_price`,
    currentCategory: 'onetime',
    currentProduct: `onetime_${currentTubIndex}`
  });
  const priceOTP = ProductPrice({
    type: `price`,
    currentCategory: 'onetime',
    currentProduct: `onetime_${currentTubIndex}`
  });
  const dataOTP = ProductPrice({
    type: `data`,
    currentCategory: 'onetime',
    currentProduct: `onetime_${currentTubIndex}`
  });

  const discountedPriceSub = ProductPrice({
    type: `discounted_price`,
    currentCategory: 'subscriptions',
    currentProduct: `subscription_${currentTubIndex}`
  });
  const priceSub = ProductPrice({
    type: `price`,
    currentCategory: 'subscriptions',
    currentProduct: `subscription_${currentTubIndex}`
  });
  const dataSub = ProductPrice({
    type: `data`,
    currentCategory: 'subscriptions',
    currentProduct: `subscription_${currentTubIndex}`
  });

  const SelectContainer = () => {
    const frequencyOnChange = evt => {
      setFrequency(evt.target.value);
    };

    const handleSelectChange = ({ value }) => {
      setFrequency(value);
    };

    const options = subscriptionSchedules.map(item => {
      return {
        value: item.id,
        label: item.label
      };
    });

    return useSelectComponent ? (
      <Select
        classNamePrefix="frequency-select"
        options={options}
        onChange={handleSelectChange}
        inputProps={{ readOnly: true }}
        isSearchable={false}
        defaultValue={options.filter(option => option.value === frequency)}
        inputId="frequency-select-input"
      />
    ) : (
      <select
        defaultValue={frequency}
        aria-label="Frequency"
        onBlur={frequencyOnChange}
        onChange={frequencyOnChange}
      >
        {subscriptionSchedules.map(({ id, label }) => {
          return (
            <option key={id} value={id}>
              {label}
            </option>
          );
        })}
      </select>
    );
  };

  return (
    <>
      <S.Container>
        <S.OneTimeContainer
          onClick={() => handleCategoryClick('onetime')}
          active={currentCategory === 'onetime'}
        >
          <S.FirstLine>
            <S.RadioInput active={currentCategory === 'onetime'} />{' '}
            <S.Title>{langTabOnetime}</S.Title>
          </S.FirstLine>
          <S.SecondLine>
            <S.DiscountedPrice>
              {currencySymbol}
              {discountedPriceOTP}
            </S.DiscountedPrice>
            <S.Price>
              {currencySymbol}
              {priceOTP}
            </S.Price>
            <S.Savings>(Extra {dataOTP?.discount_percentage}% Off)</S.Savings>
          </S.SecondLine>
        </S.OneTimeContainer>
        <S.SubscriptionContainer
          onClick={() => handleCategoryClick('subscriptions')}
          active={currentCategory === 'subscriptions'}
        >
          <S.FirstLine>
            <S.RadioInput active={currentCategory === 'subscriptions'} />
            <S.Title>{langTabSubscribe}</S.Title>
            <S.SubIcon src="/images/sub-icon.svg" alt="Sub Icon" />
          </S.FirstLine>
          <S.SecondLine>
            <S.DiscountedPrice sub={true}>
              {currencySymbol}
              {discountedPriceSub}
            </S.DiscountedPrice>
            <S.Price sub={true}>
              {currencySymbol}
              {priceSub}
            </S.Price>
            <S.Savings sub={true}>
              (Extra {dataSub?.discount_percentage}% Off)
            </S.Savings>
          </S.SecondLine>
          <S.FrequencyLabel htmlFor="frequency-select-input">
            Deliver Every
          </S.FrequencyLabel>
          <SelectContainer />
        </S.SubscriptionContainer>
        <S.WhySubscribeContainer>
          <S.FirstLine>
            <S.SubIcon2 src="/images/sub-icon.svg" alt="Sub Icon" />
            <S.Title className="title2">Why Subscribe?</S.Title>
          </S.FirstLine>
          <S.ListContainer>
            <S.ListItem>Save 25% on monthly supply</S.ListItem>
            <S.ListItem>Unlock massive discounts on other products</S.ListItem>
            <S.ListItem>Change, pause or skip deliveries</S.ListItem>
            <S.ListItem>
              No fees,{' '}
              <S.SubPopup onClick={() => setShowSubPopup(true)}>
                cancel online
              </S.SubPopup>
            </S.ListItem>
          </S.ListContainer>
        </S.WhySubscribeContainer>
      </S.Container>
      {showSubPopup && (
        <SubPopup
          version={language?.benefitTwoPopupVersion ?? 'english'}
          show={showSubPopup}
          setShowSubPopup={setShowSubPopup}
          faqURL={faqURL}
        />
      )}
    </>
  );
}

export default PackageSelectorV2;
