import React from 'react';
import loadable from '@loadable/component';

const HeaderV1 = loadable(() => import('./HeaderV1'));
const HeaderV2 = loadable(() => import('./HeaderV2'));

const Header = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <HeaderV2 {...props} />;
    default:
    case 'version1':
      return <HeaderV1 {...props} />;
  }
};

export default Header;
