import React from 'react';
import * as S from './TopBannerV2.styles';

const TopBanner = props => {
  const { banner_text, banner_text_two, sticky, banner_icon } = props;

  return (
    <S.Container className={sticky ? 'sticky' : ''}>
      <S.Banner className={sticky ? 'sticky' : ''}>
        <S.BannerIcon alt="preorder_icon" src={banner_icon} />
        <S.BannerTextOne>{banner_text}</S.BannerTextOne>
        <S.BannerIcon alt="preorder_icon" src={banner_icon} />
        <S.BannerTextTwo>{banner_text_two}</S.BannerTextTwo>
      </S.Banner>
    </S.Container>
  );
};

export default TopBanner;
