import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const Video2 = loadable(() => import('./Video2'));

Builder.registerComponent(Video2, {
  name: 'Video 2',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'provider',
      type: 'string',
      defaultValue: 'youtube',
      enum: ['youtube', 'vidalytics']
    },
    {
      name: 'vidalyticsId',
      type: 'string',
      required: false,
      defaultValue: '',
      showIf: o => o.get('provider') === 'vidalytics',
      helperText: `should include 'vidalytics_embed_'`
    },
    {
      name: 'vidalyticsUrl',
      type: 'string',
      required: false,
      defaultValue: '',
      showIf: o => o.get('provider') === 'vidalytics',
      helperText: `should include trailing slash`
    },
    {
      name: 'secondVideo',
      type: 'boolean',
      defaultValue: false,
      showIf: o => o.get('provider') === 'vidalytics'
    },
    {
      name: 'video_id',
      type: 'string',
      required: true,
      defaultValue: 'TIfwLWp0Qns',
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'defaultPlay',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'isPlaylist',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'noCookie',
      type: 'boolean',
      defaultValue: true,
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'mute',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'isMobile',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'mobileResolution',
      type: 'string',
      enum: ['hqdefault', 'sddefault', 'maxresdefault'],
      defaultValue: 'hqdefault',
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'desktopResolution',
      type: 'string',
      enum: ['hqdefault', 'sddefault', 'maxresdefault'],
      defaultValue: 'maxresdefault',
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'lazyImage',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'imageAltText',
      type: 'string',
      defaultValue: "YouTube's thumbnail for this video",
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    },
    {
      name: 'iframeTitle',
      type: 'string',
      defaultValue: 'YouTube video.',
      advanced: true,
      showIf: o => o.get('provider') === 'youtube'
    }
  ]
});
