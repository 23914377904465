import { Builder, withChildren } from '@builder.io/react';
import AddToCartButton from './AddToCartButton';

Builder.registerComponent(withChildren(AddToCartButton), {
  name: 'Add To Cart Button',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: [
        'version1',
        'version2',
        'version3',
        'version4',
        'submit-local-cart'
      ],
      defaultValue: 'version1'
    },
    {
      name: 'buttonText',
      type: 'string',
      required: true,
      defaultValue: 'Add To Cart'
    },
    {
      name: 'overrideColors',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#FFF',
      advanced: true,
      showIf: o => o.get('overrideColors')
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#e26447',
      advanced: true,
      showIf: o => o.get('overrideColors')
    },
    {
      name: 'backgroundColorHover',
      type: 'color',
      defaultValue: '#C34D32',
      advanced: true,
      showIf: o => o.get('overrideColors')
    },
    {
      name: 'triggerCheckoutButton',
      type: 'string',
      required: false,
      enum: [
        'none',
        'paypal',
        'google-pay',
        'apple-pay',
        'cross-sell',
        'bump-offer-new-page'
      ],
      defaultValue: 'none',
      advanced: true
    },
    {
      name: 'bumpOfferNewPageUrl',
      type: 'string',
      defaultValue: '/bump-offer-new-page',
      advanced: true,
      showIf: options =>
        options.get('triggerCheckoutButton') === 'bump-offer-new-page'
    },
    {
      name: 'crossSellEndpointApiUrl',
      type: 'string',
      defaultValue:
        'https://subscription.altitudecheckout.com/api/crossell/offer',
      advanced: true,
      showIf: options => options.get('triggerCheckoutButton') === 'cross-sell',
      helperText: `Staging URL: https://staging-subscription.thepetlabco.com/api/crossell/offer
      Live URL: https://subscription.altitudecheckout.com/api/crossell/offer
      `
    },
    {
      name: 'crossSellFirstDeliveryAmount',
      type: 'string',
      advanced: true,
      showIf: options => options.get('triggerCheckoutButton') === 'cross-sell'
    },
    {
      name: 'crossSellThenDeliveryAmount',
      type: 'string',
      advanced: true,
      showIf: options => options.get('triggerCheckoutButton') === 'cross-sell'
    },
    {
      name: 'crossSellShowPopup',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => options.get('triggerCheckoutButton') === 'cross-sell'
    },
    {
      name: 'hideExpressCheckout',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    }
  ]
});
