import styled from 'styled-components';

export const Container = styled.div`
  &.sticky {
    padding: 10px 0 40px;
  }
`;

export const Banner = styled.div`
  text-align: center;
  background: #213f4e;
  padding: 10px 0 10px;
  color: #fff;
  z-index: 10;
  width: 100%;
  top: 0;
  &.sticky {
    position: fixed;
  }
`;

export const TextOne = styled.span`
  font-weight: 600;
  color: #efb62f;
`;

export const TextTwo = styled.span`
  font-weight: 600;
  color: white;
`;
