export const formatPrice = (value, priceSettings = {}) => {
  const {
    currencySymbol = `$`,
    commaDecimalPoint,
    currencySymbolToEnd
  } = priceSettings;
  let amount = parseFloat(value).toFixed(2);

  if (commaDecimalPoint) {
    amount = amount.replace('.', ',');
  }
  if (currencySymbolToEnd) {
    amount = `${amount}${currencySymbol}`;
  } else {
    amount = `${currencySymbol}${amount}`;
  }
  return amount;
};
