import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const RepurchaseConfirmation = loadable(() =>
  import('./RepurchaseConfirmation')
);

Builder.registerComponent(RepurchaseConfirmation, {
  name: 'Repurchase Confirmation',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'agreementsText',
      type: 'richText'
    }
  ]
});
