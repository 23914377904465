import styled from 'styled-components';

export const Container = styled.div`
  &.sticky {
    padding: 10px 0 25px;
  }
`;

export const Banner = styled.div`
  text-align: center;
  background: #d26546;
  padding: 10px 0 5px;
  color: #fff;
  z-index: 10;
  width: 100%;
  top: 0;
  &.sticky {
    position: fixed;
  }
`;
