import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './PackageSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { CategorySavings, ProductPrice } from '../../utils/productPrice';
import { calculateDiscount } from '../../utils/calculateDiscount';
import useLocalStorage from '../../hooks/useLocalStorage';
import loadable from '@loadable/component';
import Select from 'react-select';
import { formatPrice } from '../../utils/formatPrice';

const SubPopup = loadable(() => import('../ProductSelector/SubPopup'));
const KlarnaPopup = loadable(() => import('./KlarnaPopup'));
const SubLockInSelector = loadable(() => import('./SubLockInSelector'));
const BandanaSelector = loadable(() => import('./BandanaSelector'));

const PackageSelector = props => {
  const {
    children,
    subscriptionSchedules,
    faqURL,
    showPriceComparison,
    invertPriceComparison,
    hideSubsTab,
    hideOtpTab,
    useSelectComponent,
    showKlarna,
    otpKlarna,
    otpKlarnaInstallments,
    subKlarna,
    subKlarnaInstallments,
    showBandanaSelector,
    bandanaSelectorItems,
    withFreeGift,
    subLockInEnabled,
    subLockInPeriods,
    currencySymbolToEnd,
    commaDecimalPoint,
    tabTwoLines,
    hideTabSavings,
    hideMainChild,
    useChildrenInTabContent,
    tabContentChildIndexForSub,
    tabContentChildIndexForOtp,
    language,
    hideSubsSavings,
    hideOtpSavings,
    hideParenthesesSavings,
    subSavingsAddon,
    otpSavingsAddon,
    subscriptionInfo,
    percentageSavings,
    style = `default`
  } = props;

  const context = useContext(FunnelContext);
  const {
    currency,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    currentTubIndex,
    frequency,
    setFrequency,
    pageDesign,
    extraObjects,
    setExtraObjects
  } = context;

  const priceSettings = {
    currencySymbol: currency?.symbol,
    currencySymbolToEnd,
    commaDecimalPoint
  };

  const [initDefaultSelection, setInitDefaultSelection] = useState(false);
  const [showSubPopup, setShowSubPopup] = useState(false);
  const [showKlarnaPopup, setShowKlarnaPopup] = useState(false);
  const [checkTabs, setCheckTabs] = useState(false);
  const [, setExtraObjects2] = useLocalStorage('extraObjects');

  const initKlarna = useCallback(
    currentCategory => {
      if (showKlarna) {
        if (currentCategory === `subscriptions`) {
          setExtraObjects(
            Object.assign(extraObjects, {
              klarna_enabled: subKlarna ? '1' : '0'
            })
          );
          setExtraObjects2(
            Object.assign(extraObjects, {
              klarna_enabled: subKlarna ? '1' : '0'
            })
          );
        } else if (currentCategory === `onetime`) {
          setExtraObjects(
            Object.assign(extraObjects, {
              klarna_enabled: otpKlarna ? '1' : '0'
            })
          );
          setExtraObjects2(
            Object.assign(extraObjects, {
              klarna_enabled: otpKlarna ? '1' : '0'
            })
          );
        }
      }
    },
    [
      extraObjects,
      setExtraObjects,
      showKlarna,
      otpKlarna,
      subKlarna,
      setExtraObjects2
    ]
  );

  useEffect(() => {
    if (!checkTabs) {
      if (hideSubsTab) {
        setCurrentCategory('onetime');
        setCurrentProduct(`onetime_${currentTubIndex}`);
      }
      if (hideOtpTab) {
        setCurrentCategory('subscriptions');
        setCurrentProduct(`subscription_${currentTubIndex}`);
      }

      setExtraObjects(
        Object.assign(extraObjects, {
          currentCategory
        })
      );
      setExtraObjects2(
        Object.assign(extraObjects, {
          currentCategory
        })
      );

      initKlarna(currentCategory || 'subscriptions');

      return () => setCheckTabs(true);
    }
  }, [
    hideSubsTab,
    hideOtpTab,
    checkTabs,
    setCheckTabs,
    currentTubIndex,
    currentCategory,
    setCurrentCategory,
    setCurrentProduct,
    extraObjects,
    setExtraObjects,
    showKlarna,
    otpKlarna,
    subKlarna,
    initKlarna,
    setExtraObjects2
  ]);

  const scheduleExtraObjects = useCallback(
    value => {
      const [{ extraObjects: extraObjs }] = subscriptionSchedules.filter(
        s => s.id === value
      );
      if (extraObjs) {
        extraObjs.map(({ key, value }) => {
          setExtraObjects(
            Object.assign(extraObjects, {
              [key]: value
            })
          );
          setExtraObjects2(
            Object.assign(extraObjects, {
              [key]: value
            })
          );
          return null;
        });
      }
    },
    [subscriptionSchedules, setExtraObjects, extraObjects, setExtraObjects2]
  );

  useEffect(() => {
    if (!initDefaultSelection) {
      const defaultSelection = subscriptionSchedules.filter(
        s => s.defaultSelection
      );
      if (defaultSelection.length > 0) {
        scheduleExtraObjects(defaultSelection[0].id);
        setFrequency(defaultSelection[0].id);
      } else {
        const defaultFreq = subscriptionSchedules.filter(
          s => s.id === frequency
        );
        if (defaultFreq.length > 0) {
          scheduleExtraObjects(frequency);
        } else {
          const firstFreq = subscriptionSchedules[0] ?? null;
          if (firstFreq) {
            scheduleExtraObjects(firstFreq.id);
            setFrequency(firstFreq.id);
          }
        }
      }
      return setInitDefaultSelection(true);
    }
  }, [
    frequency,
    scheduleExtraObjects,
    setFrequency,
    subscriptionSchedules,
    initDefaultSelection,
    setInitDefaultSelection
  ]);

  const frequencyOnChange = evt => {
    setFrequency(evt.target.value);
    scheduleExtraObjects(evt.target.value);
  };

  const handleSelectChange = ({ value }) => {
    setFrequency(value);
    scheduleExtraObjects(value);
  };

  const FrequencySelect = () => {
    if (subscriptionSchedules) {
      const options = [];
      subscriptionSchedules.map(item => {
        return options.push({ value: item.id, label: item.label });
      });

      const SelectContainer = () => {
        if (useSelectComponent) {
          return (
            <Select
              classNamePrefix="frequency-select"
              options={options}
              onChange={handleSelectChange}
              inputProps={{ readOnly: true }}
              isSearchable={false}
              defaultValue={options.filter(
                option => option.value === frequency
              )}
              inputId="frequency-select-input"
            />
          );
        } else {
          return (
            <select
              id="frequency-select-input"
              defaultValue={frequency}
              aria-label="Frequency"
              onBlur={frequencyOnChange}
              onChange={frequencyOnChange}
            >
              {subscriptionSchedules.map(({ id, label }) => {
                return (
                  <option key={id} value={id}>
                    {label}
                  </option>
                );
              })}
            </select>
          );
        }
      };

      const langDeliveryFrequency =
        language?.deliveryFrequency ?? `Delivery Frequency`;
      return (
        <S.Frequency pageDesign={pageDesign} className="frequency">
          <S.FrequencyLabel htmlFor="frequency-select-input">
            {langDeliveryFrequency}
          </S.FrequencyLabel>
          <SelectContainer />
        </S.Frequency>
      );
    } else {
      return '';
    }
  };

  const PriceComparison = () => {
    const discountedPrice = ProductPrice({
      type: `discounted_price`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    const price = ProductPrice({
      type: `price`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    if (showPriceComparison) {
      return (
        <S.Price className="price-comparison" pageDesign={pageDesign}>
          {!invertPriceComparison && (
            <>{formatPrice(discountedPrice, priceSettings)}</>
          )}
          {discountedPrice === price ? (
            ``
          ) : (
            <span>{formatPrice(price, priceSettings)}</span>
          )}{' '}
          {invertPriceComparison && formatPrice(discountedPrice, priceSettings)}
        </S.Price>
      );
    } else {
      return ``;
    }
  };

  const KlarnaContainer = ({ type }) => {
    const KlarnaMessage = () => {
      const discounted_price = ProductPrice({
        type: 'discounted_price',
        currentCategory: currentCategory,
        currentProduct: currentProduct
      });

      let klarna_installment = 0.0;
      let installments = 0;
      if (subKlarna && type === `sub`) {
        installments = subKlarnaInstallments || 3;
        klarna_installment =
          parseFloat(discounted_price) / parseInt(installments);
      }
      if (otpKlarna && type === `otp`) {
        installments = otpKlarnaInstallments || 3;
        klarna_installment =
          parseFloat(discounted_price) / parseInt(installments);
      }

      return (
        <S.Klarna>
          Or make {installments} interest-free payments of{' '}
          {formatPrice(klarna_installment.toFixed(2), priceSettings)}.{' '}
          <S.KlarnaPopup onClick={() => setShowKlarnaPopup(true)}>
            Learn more
          </S.KlarnaPopup>
        </S.Klarna>
      );
    };

    if (
      showKlarna &&
      ((subKlarna && type === `sub`) || (otpKlarna && type === `otp`))
    ) {
      return <KlarnaMessage />;
    } else {
      return ``;
    }
  };

  const SubExtraInfo = () => {
    const display_title = ProductPrice({
      type: `display_title`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });

    return (
      <S.SubExtraInfoContent>
        <div className={'display-title'}>{display_title}</div>
      </S.SubExtraInfoContent>
    );
  };

  const TabContent = () => {
    const discountedPrice = ProductPrice({
      type: `discounted_price`,
      tub: currentTubIndex,
      currentCategory: 'subscriptions',
      currentProduct: `subscription_${currentTubIndex}`
    });
    const price = ProductPrice({
      type: `price`,
      tub: currentTubIndex,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });

    if (currentCategory === `subscriptions`) {
      const langBenefitOneTitle =
        language?.benefitOneTitle ?? `Flexible subscription plan`;
      const langBenefitOneSubTitle =
        language?.benefitOneSubTitle ?? `Change, pause or skip your delivery`;
      const langBenefitTwoTitle = language?.benefitTwoTitle ?? `Easy Cancel`;
      const langBenefitTwoSubTitle =
        language?.benefitTwoSubTitle ??
        `You can |manage or cancel your subscription online`;
      const [
        langBenefitTwoSubTitle1,
        langBenefitTwoSubTitle2,
        langBenefitTwoSubTitle3
      ] = langBenefitTwoSubTitle.split('|');
      return (
        <S.SubscriptionContent className="sub-content">
          <S.SubscriptionDetails>
            <div>
              <PriceComparison />
              {percentageSavings && (
                <S.DiscountPercentage>
                  <Savings
                    currentCategory="subscriptions"
                    currentProduct={`subscription_${currentTubIndex}`}
                    addon={subSavingsAddon}
                  />
                  <span>{` `}</span>
                  <span>
                    {`(${calculateDiscount(price, discountedPrice)}% OFF)`}
                  </span>
                </S.DiscountPercentage>
              )}
            </div>
            {subscriptionInfo && <SubExtraInfo />}
          </S.SubscriptionDetails>

          {subscriptionSchedules?.length > 0 && (
            <FrequencySelect pageDesign={pageDesign} />
          )}

          <BandanaSelector
            show={showBandanaSelector}
            items={bandanaSelectorItems}
          />

          <SubLockInSelector show={subLockInEnabled} items={subLockInPeriods} />

          <S.SubsPlan pageDesign={pageDesign} className="sub-plan">
            <S.SubsPlanItem pageDesign={pageDesign}>
              <S.SubsPlanItemTitle pageDesign={pageDesign}>
                <i className="icon icon-pencil"></i>
                {langBenefitOneTitle}
              </S.SubsPlanItemTitle>
              <p>{langBenefitOneSubTitle}</p>
            </S.SubsPlanItem>
            <S.SubsPlanItem pageDesign={pageDesign}>
              <S.SubsPlanItemTitle pageDesign={pageDesign}>
                <i className="icon icon-close"></i>
                {langBenefitTwoTitle}
              </S.SubsPlanItemTitle>
              <p>
                {langBenefitTwoSubTitle1?.trim() ?? `You can`}{' '}
                <S.SubPopup
                  pageDesign={pageDesign}
                  onClick={() => setShowSubPopup(true)}
                >
                  {langBenefitTwoSubTitle2?.trim() ??
                    `manage or cancel your subscription online`}
                </S.SubPopup>{' '}
                {langBenefitTwoSubTitle3?.trim() ?? ``}
              </p>
            </S.SubsPlanItem>
          </S.SubsPlan>
          <KlarnaContainer type="sub" />
        </S.SubscriptionContent>
      );
    } else {
      const onetimeInnerHtml = () => {
        let withFreeGiftText = language?.withFreeGift ?? `+ A FREE Gift`;
        let langOnetimeContent =
          language?.onetimeContent ??
          `You can have the same for only <strong>[sub-price] [free-gift]</strong> if you subscribe. That’s a saving of [savings] and you can change or cancel your subscription! 😱😱😱`;

        const CategorySavingsAmount = CategorySavings({
          type: 'discounted_price',
          tub: currentTubIndex
        });
        langOnetimeContent = langOnetimeContent?.replace(
          /\[savings\]/gm,
          formatPrice(CategorySavingsAmount, priceSettings)
        );
        langOnetimeContent = langOnetimeContent?.replace(
          /\[sub-price\]/gm,
          formatPrice(discountedPrice, priceSettings)
        );
        langOnetimeContent = langOnetimeContent?.replace(
          /\[currency-symbol\]/gm,
          priceSettings.currencySymbol
        );
        langOnetimeContent = langOnetimeContent?.replace(
          /\[free-gift\]/gm,
          withFreeGift ? withFreeGiftText : ''
        );

        return {
          __html: langOnetimeContent
        };
      };
      return (
        <>
          <S.OnetimeContent pageDesign={pageDesign} className="otp-content">
            <PriceComparison />
            <p dangerouslySetInnerHTML={onetimeInnerHtml()} />
          </S.OnetimeContent>
          <KlarnaContainer type="otp" />
        </>
      );
    }
  };

  const Savings = ({ currentCategory, currentProduct, addon }) => {
    const savings = ProductPrice({
      type: 'savings',
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    const _savings = parseFloat(savings) + parseFloat(addon ?? 0);
    const langTabSave = language?.tabSave ?? `save`;
    if (parseFloat(_savings)) {
      return (
        !hideTabSavings && (
          <>
            <span className={tabTwoLines ? 'two-lines' : ''}>
              {!hideParenthesesSavings && `(`}
              {langTabSave} {formatPrice(_savings, priceSettings)}
              {!hideParenthesesSavings && `)`}
            </span>
          </>
        )
      );
    } else {
      return ``;
    }
  };

  const SubsTabItem = () => {
    if (hideSubsTab) {
      return ``;
    } else {
      const langTabSubscribe = language?.tabSubscribe ?? `Subscribe`;

      return (
        <S.TabItem
          onClick={() => {
            if (typeof setCurrentCategory === 'function') {
              setCurrentCategory('subscriptions');
              setCurrentProduct(`subscription_${currentTubIndex}`);
              initKlarna('subscriptions');
              setExtraObjects(
                Object.assign(extraObjects, {
                  currentCategory: 'subscriptions'
                })
              );
            }
          }}
          hideOtp={hideOtpTab}
          pageDesign={pageDesign}
          className={`sub ${
            currentCategory === `subscriptions` ? 'active' : ''
          }`}
        >
          <label htmlFor={`${props.builderBlock.id}_sub`}>
            <input
              onChange={() => {
                if (typeof setCurrentCategory === 'function') {
                  setCurrentCategory('subscriptions');
                  setCurrentProduct(`subscription_${currentTubIndex}`);
                  initKlarna('subscriptions');
                  setExtraObjects(
                    Object.assign(extraObjects, {
                      currentCategory
                    })
                  );
                }
              }}
              id={`${props.builderBlock.id}_sub`}
              name={`${props.builderBlock.id}_tab`}
              type="radio"
              checked={currentCategory === `subscriptions` ? 'checked' : ''}
            />
            <S.TabItemDot
              pageDesign={pageDesign}
              className={`${
                currentCategory === `subscriptions` ? 'active' : ''
              }`}
            ></S.TabItemDot>
            <S.TabItemTitle pageDesign={pageDesign}>
              {langTabSubscribe}{' '}
              {!hideSubsSavings && (
                <Savings
                  currentCategory="subscriptions"
                  currentProduct={`subscription_${currentTubIndex}`}
                  addon={subSavingsAddon}
                />
              )}
            </S.TabItemTitle>
          </label>
        </S.TabItem>
      );
    }
  };

  const OtpTabItem = () => {
    if (hideOtpTab) {
      return ``;
    } else {
      const langTabOnetime = language?.tabOnetime ?? `One-time`;

      return (
        <S.TabItem
          onClick={() => {
            if (typeof setCurrentCategory === 'function') {
              setCurrentCategory('onetime');
              setCurrentProduct(`onetime_${currentTubIndex}`);
              initKlarna('onetime');
              setExtraObjects(
                Object.assign(extraObjects, {
                  currentCategory: 'onetime'
                })
              );
            }
          }}
          hideSubs={hideSubsTab}
          pageDesign={pageDesign}
          className={`otp ${currentCategory === `onetime` ? 'active' : ''}`}
        >
          <label htmlFor={`${props.builderBlock.id}_otp`}>
            <input
              onChange={() => {
                if (typeof setCurrentCategory === 'function') {
                  setCurrentCategory('onetime');
                  setCurrentProduct(`onetime_${currentTubIndex}`);
                  initKlarna('onetime');
                  setExtraObjects(
                    Object.assign(extraObjects, {
                      currentCategory
                    })
                  );
                }
              }}
              id={`${props.builderBlock.id}_otp`}
              name={`${props.builderBlock.id}_tab`}
              type="radio"
              checked={currentCategory === `onetime` ? 'checked' : ''}
            />
            <S.TabItemDot
              pageDesign={pageDesign}
              className={`${currentCategory === `onetime` ? 'active' : ''}`}
            ></S.TabItemDot>
            <S.TabItemTitle pageDesign={pageDesign}>
              {langTabOnetime}{' '}
              {!hideOtpSavings && (
                <Savings
                  currentCategory="onetime"
                  currentProduct={`onetime_${currentTubIndex}`}
                  addon={otpSavingsAddon}
                />
              )}
            </S.TabItemTitle>
          </label>
        </S.TabItem>
      );
    }
  };

  const TabContentChildren = () =>
    currentCategory === `subscriptions` ? (
      <>{children[tabContentChildIndexForSub || 0]}</>
    ) : (
      <>{children[tabContentChildIndexForOtp || 0]}</>
    );

  return (
    <S.Container
      pageDesign={pageDesign}
      className={`package-selector ${style}`}
    >
      <S.Tabs className="tabs">
        <SubsTabItem />
        <OtpTabItem />
      </S.Tabs>

      <S.TabContent className="tab-content">
        {!hideMainChild && <>{children}</>}
        {useChildrenInTabContent ? <TabContentChildren /> : <TabContent />}
      </S.TabContent>

      <SubPopup
        version={language?.benefitTwoPopupVersion ?? 'english'}
        show={showSubPopup}
        setShowSubPopup={setShowSubPopup}
        faqURL={faqURL}
      />
      <KlarnaPopup
        installments={
          currentCategory === `onetime`
            ? otpKlarnaInstallments
            : subKlarnaInstallments
        }
        show={showKlarnaPopup}
        setShowKlarnaPopup={setShowKlarnaPopup}
      />
    </S.Container>
  );
};

export default PackageSelector;
