import { builder, Builder } from '@builder.io/react';

// a set of widgets you can use in the editor, optional.
import '@builder.io/widgets';

/**
 * Import all custom components so you can use in the builder.io editor
 * https://www.builder.io/c/docs/custom-react-components
 */
import './components/TopBanner/TopBanner.builder';
import './components/Header/Header.builder';
import './components/Ingredient/Ingredient.builder';
import './components/Faqs/Faqs.builder';
import './components/Footer/Footer.builder';
import './components/Title/Title.builder';
import './components/Video2/Video2.builder';
import './components/Quote/Quote.builder';
import './components/FBCommentPlugin/FBCommentPlugin.builder';
import './components/TrustPilotPlugin/TrustPilotPlugin.builder';
import './components/StampedPlugin/StampedPlugin.builder';
import './components/FloatingCountdown/FloatingCountdown.builder';
import './components/ImageCarousel/ImageCarousel.builder';
import './components/ProductTitle/ProductTitle.builder';
// import './components/ProductSelector/ProductSelector.builder';
import './components/TubSelector/TubSelector.builder';
import './components/PackageSelector/PackageSelector.builder';
import './components/BumpOfferSelector/BumpOfferSelector.builder';
import './components/AddToCartButton/AddToCartButton.builder';
import './components/PriceComparison/PriceComparison.builder';
import './components/PricePerChew/PricePerChew.builder';
import './components/SavingsPopup/SavingsPopup.builder';
import './components/CountDown/CountDown.builder';
import './components/TimeLimitContainer/TimeLimitContainer.builder';
import './components/NavLinks/NavLinks.builder';
import './components/FunnelText/FunnelText.builder';
import './components/CategoryContainer/CategoryContainer.builder';
import './components/FormInput/FormInput.builder';
import './components/CookieContainer/CookieContainer.builder';
import './components/ActiveSubscriptionSelector/ActiveSubscriptionSelector.builder';
import './components/ActiveSubscriptionConfirmation/ActiveSubscriptionConfirmation.builder';
import './components/LottieAnimation/LottieAnimation.builder';
import './components/FreeGiftSelector/FreeGiftSelector.builder';
import './components/ExtraObjectsContainer/ExtraObjectsContainer.builder';
import './components/TubIndexSelector/TubIndexSelector.builder';
import './components/UserAddress/UserAddress.builder';
import './components/UserPaymentMethod/UserPaymentMethod.builder';
import './components/UserSubscriptions/UserSubscriptions.builder';
import './components/UserText/UserText.builder';
import './components/LockInSelector/LockInSelector.builder';
import './components/LazyLoadContainer/LazyLoadContainer.builder';
import './components/ProductItem/ProductItem.builder';
import './components/ProductItemText/ProductItemText.builder';
import './components/SubPopupText/SubPopupText.builder';
import './components/DeliveryFrequency/DeliveryFrequency.builder';
import './components/Toggle/Toggle.builder';
import './components/ScrollButtonContainer/ScrollButtonContainer.builder';
import './components/AddToLocalCartButton/AddToLocalCartButton.builder';
import './components/LocalCartText/LocalCartText.builder';
import './components/LocalCartItems/LocalCartItems.builder';
import './components/CategorySelector/CategorySelector.builder';
import './components/SlickSlider/SlickSlider.builder';
import './components/ProductImages/ProductImages.builder';
import './components/DelaySubscription/DelaySubscription.builder';
import './components/DelayCalendar/DelayCalendar.builder';
import './components/DelayButton/DelayButton.builder';
import './components/DelayText/DelayText.builder';
import './components/LoadingCover/LoadingCover.builder';
import './components/RepurchaseContainer/RepurchaseContainer.builder';
import './components/RepurchaseConfirmation/RepurchaseConfirmation.builder';
import './components/Receipt/Receipt.builder';
import './components/LinkError/LinkError.builder';
import './components/OkendoReviewsWidget/OkendoReviewsWidget.builder';
import './components/FatherLayerContainer/FatherLayerContainer.builder';
import './components/ComparePlansTable/ComparePlansTable.builder';

// sales letter components
import './components/sales-letter/Container1/Container1.builder';

import './components/slides/slides-components';

builder.init(process.env.GATSBY_BUILDER_API_KEY);

// groupings
Builder.register('insertMenu', {
  name: 'User Subscription Components',
  items: [
    { name: 'Active Subscription Selector' },
    { name: 'Active Subscription Confirmation' },
    { name: 'User Address' },
    { name: 'User Payment Method' },
    { name: 'User Subscriptions' },
    { name: 'User Text' }
  ]
});
