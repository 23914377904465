import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const NextButtonContainer = loadable(() => import('./NextButtonContainer'));

Builder.registerComponent(withChildren(NextButtonContainer), {
  name: 'Slides:NextButtonContainer',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'nextLayerId',
      type: 'string',
      defaultValue: 'layer-1',
      required: true
    },
    {
      name: 'answers',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
          required: true
        },
        {
          name: 'value',
          type: 'string',
          required: false
        }
      ],
      advanced: true
    },
    {
      name: 'disableOnEmptyKey',
      type: 'string',
      required: false,
      advanced: true
    },
    {
      name: 'validateAnswerKeys',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
          required: true
        }
      ],
      required: false,
      advanced: true
    },
    {
      name: 'extraObjects',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
          required: true
        },
        {
          name: 'value',
          type: 'string',
          required: false
        }
      ],
      advanced: true
    },
    {
      name: 'insertQueryParam',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'queryParamString',
      type: 'string',
      defaultValue: 'layer',
      advanced: true,
      showIf: o => o.get('insertQueryParam')
    },
    {
      name: 'clearPreviousLayers',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => o.get('insertQueryParam')
    },
    {
      name: 'triggerScrollToTop',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'insertAnswersToQueryParams',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
          required: true
        }
      ],
      advanced: true
    },
    {
      name: 'insertDatalayer',
      type: 'list',
      subFields: [
        {
          name: 'objects',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              required: true
            },
            {
              name: 'value',
              type: 'string',
              required: true
            },
            {
              name: 'valueInAnswers',
              type: 'boolean',
              required: false,
              defaultValue: false,
              helperText: 'Set answers object key in the `value` input'
            }
          ]
        }
      ],
      advanced: true
    }
  ]
});
