import React from 'react';
import * as S from './TopBannerV1.styles';

const TopBanner = props => {
  const { content, sticky } = props;

  return (
    <S.Container className={sticky ? 'sticky' : ''}>
      <S.Banner className={sticky ? 'sticky' : ''}>{content}</S.Banner>
    </S.Container>
  );
};

export default TopBanner;
