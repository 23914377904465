import React from 'react';
import * as S from './TopBannerV3.styles';

const TopBanner = props => {
  const { sticky } = props;

  return (
    <S.Container className={sticky ? 'sticky' : ''}>
      <S.Banner className={sticky ? 'sticky' : ''}>
        <S.TextOne className="free">FREE SHIPPING</S.TextOne>{' '}
        <S.TextTwo className="orders">ON ALL ORDERS!</S.TextTwo>{' '}
      </S.Banner>
    </S.Container>
  );
};

export default TopBanner;
