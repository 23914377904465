import { Builder, withChildren } from '@builder.io/react';
import Toggle from './Toggle';

Builder.registerComponent(withChildren(Toggle), {
  name: 'Toggle',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'isModal',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
