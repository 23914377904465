import React from 'react';
import loadable from '@loadable/component';

const AddToCartButtonV1 = loadable(() => import('./AddToCartButtonV1'));
const AddToCartButtonV2 = loadable(() => import('./AddToCartButtonV2'));
const AddToCartButtonV3 = loadable(() => import('./AddToCartButtonV3'));
const AddToCartButtonV4 = loadable(() => import('./AddToCartButtonV4'));
const SubmitLocalCart = loadable(() => import('./SubmitLocalCart'));

const AddToCartButton = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'submit-local-cart':
      return <SubmitLocalCart {...props} />;
    case 'version4':
      return <AddToCartButtonV4 {...props} />;
    case 'version3':
      return <AddToCartButtonV3 {...props} />;
    case 'version2':
      return <AddToCartButtonV2 {...props} />;
    default:
    case 'version1':
      return <AddToCartButtonV1 {...props} />;
  }
};

export default AddToCartButton;
