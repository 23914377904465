import { Builder, withChildren } from '@builder.io/react';
import CategorySelector from './CategorySelector';

Builder.registerComponent(withChildren(CategorySelector), {
  name: 'Category Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'otpLabel',
      type: 'string',
      defaultValue: 'One-Time Purchase',
      required: true
    },
    {
      name: 'subLabel',
      type: 'string',
      defaultValue: 'Subscribe and Save up to 40% + free gift!',
      required: true
    }
  ]
});
