import React from 'react';
import TopBannerV1 from './TopBannerV1';
import TopBannerV2 from './TopBannerV2';
import TopBannerV3 from './TopBannerV3';
import TopBannerV4 from './TopBannerV4';

const TopBanner = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <TopBannerV2 {...props} />;
    case 'version3':
      return <TopBannerV3 {...props} />;
    case 'version4':
      return <TopBannerV4 {...props} />;
    default:
    case 'version1':
      return <TopBannerV1 {...props} />;
  }
};

export default TopBanner;
