import React, { useContext } from 'react';
import loadable from '@loadable/component';
import FunnelContext from '../../context/FunnelContext';

const ImageCarousel = props => {
  const { images } = props;
  const { currentProduct } = useContext(FunnelContext);

  const purchaseType = currentProduct ?? 'subscription_0';
  const filteredImages = images.filter(image => image[purchaseType]);

  const ImageCarouselWrapper = loadable(
    () => import('./ImageCarouselWrapper'),
    {
      fallback: (
        <>
          <img
            src={`${filteredImages[0].image}?width=390`}
            alt={filteredImages[0].alt ? filteredImages[0].alt : `Carousel 0`}
            style={{ maxWidth: '390px', margin: '0 auto' }}
          />
        </>
      )
    }
  );

  return <ImageCarouselWrapper {...props} images={filteredImages} />;
};

export default ImageCarousel;
